import { GridSection } from "@/components/GridSection/GridSection";
import { SectionWrapper } from "@/components/GridSection/GridSection.styled";
import { ProductListItems } from "@/components/PageComponents/search/ProductListItems";
import { ProductList } from "@/components/ProductListV2/ProductList";
import { useProductListParams } from "@/components/ProductListV2/hooks/useProductListParams";
import { SiteContainer } from "@/components/common/SiteContainer/SiteContainer";
import { useCommonProductListRequestData } from "@/hooks/useProductListData/useProductListData";
import { QUERY_KEYS } from "@/react-app/constants";
import { CampaignCategorySelector } from "@/react-components/CategorySelector/Campaign";
import { withoutShowAllParameter } from "@/react-components/CategorySelector/Campaign/campaign-category-selector-helper";
import { FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER } from "@/react-components/Search/Constants";
import { fetchProductListingSmallBannersData } from "@/react-components/Search/SearchFetchCategoryContentHelper";
import { useScrollRestoration } from "@/react-components/Search/hooks/useScrollRestoration";
import { useSession } from "@/react-hooks/useSession";
import type { PreferredStores } from "@/react-utils/Cookie";
import { useSymplifyWithSanity } from "@/react-utils/Symplify/hooks";
import { fetchProductList } from "@/utils/apis/api-access";
import { mapGridSectionFromContentModule } from "@/utils/apis/content-digital-campaign-page-api";
import { isDateWithinRange } from "@/utils/date-helper";
import { getFullPageTitle } from "@/utils/page-helper";
import { useMediaQuery } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { hasNoValue, hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import {
  DigitalCampaignCategoryHeroBannerTextAlignmentEnum,
  type DigitalCampaignCategoryHeroBanner,
  type DigitalCampaignHeroBanner,
} from "@xxl/content-api";
import { log } from "@xxl/logging-utils";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import type { NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { Breadcrumbs } from "react-app/src/components/Breadcrumbs";
import CampaignHeroBanner from "react-app/src/components/CampaignHeroBanner/CampaignHeroBanner";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { mobileMediaQuery } from "react-app/src/utils/xxl-screen";
import { BreadcrumbsContainer } from "../../components/ProductDetailsPage/ProductDetailsPage.styles";
import { NewsletterLink } from "../../components/digitalCampaign/NewsletterLink";
import type { XXLAppData } from "../../global";
import { withPageData } from "../../utils/common-page-data/common-page-data";
import { getCampaignPageProps } from "../digitalcampaign/getServerSideProps";
import type { DigitalCampaignPageProps } from "../digitalcampaign/types";

const pickCampaignHeroBanner = (
  categoryPath: string,
  landingPageBanner: DigitalCampaignHeroBanner,
  categoryBanners: DigitalCampaignCategoryHeroBanner[]
) => {
  if (isEmpty(categoryPath)) {
    return landingPageBanner;
  }
  const categoryBanner = categoryBanners.find(
    ({ categoryCode }) => categoryCode === categoryPath
  );
  if (categoryBanner === undefined) {
    return landingPageBanner;
  }
  return categoryBanner;
};

const DigitalCampaignPage: NextPage<DigitalCampaignPageProps & XXLAppData> = ({
  abTestsPage,
  additionalCampaignIds,
  campaignHubUrl,
  campaignId,
  categoriesOfVisibleProducts,
  categoryBanners,
  categoryPath: categoryPathFromServer,
  environmentData: { frontEndServerUrl },
  gridSections: defaultGridSections,
  ipaperLink,
  landingPageBannerContent: defaultLandingPageBannerContent,
  landingPageSelectorLabel,
  numberOfProductsPerPage,
  bannerPriceData = [],
  productListingSmallBanners: defaultProductListingSmallBanners,
  productListData: externalProductListData,
  shouldDefaultToShowAll,
}) => {
  const { t } = useTranslations();
  const pageTitle = getFullPageTitle(
    defaultLandingPageBannerContent.heroTitle,
    t
  );
  const router = useRouter();
  const [productListingSmallBanners, setProductListingSmallBanners] = useState(
    defaultProductListingSmallBanners
  );
  const isTestVariantActive = useSymplifyWithSanity(
    "CAMPAIGN_PAGE_SANITY_TEST",
    campaignId
  );
  const { landingPage, landingPageBanner } = abTestsPage ?? {};
  const { contentModules = [] } = landingPage ?? {};
  const mappedGridSectionFromContentModule = contentModules
    .filter(isDateWithinRange)
    .map(mapGridSectionFromContentModule);

  const gridSections =
    isTestVariantActive && hasValue(mappedGridSectionFromContentModule)
      ? mappedGridSectionFromContentModule
      : defaultGridSections;
  const landingPageBannerContent =
    isTestVariantActive && hasValue(landingPageBanner)
      ? landingPageBanner
      : defaultLandingPageBannerContent;
  const [selectedHeroBanner, setSelectedHeroBanner] = useState<
    DigitalCampaignHeroBanner | DigitalCampaignCategoryHeroBanner
  >(
    pickCampaignHeroBanner(
      categoryPathFromServer[0],
      landingPageBannerContent,
      categoryBanners
    )
  );
  const isMobile = useMediaQuery(mobileMediaQuery);
  const [isAdditionalViewSelected, setIsAdditionalViewSelected] = useState(
    shouldDefaultToShowAll
      ? false
      : isNotNullOrUndefined(landingPageSelectorLabel) &&
          categoryPathFromServer.filter(withoutShowAllParameter).length === 0
  );
  const [categoryPath, setCategoryPath] = useState<string[]>(
    shouldDefaultToShowAll
      ? [FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER]
      : categoryPathFromServer
  );
  const [canonical] = useState(
    `${frontEndServerUrl}${router.asPath.split("?")[0]}`
  );
  useScrollRestoration();
  const {
    sessionState: { isLoggedIn },
  } = useSession();

  categoryPath.filter(withoutShowAllParameter).at(-1) ?? null;

  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: (string | number)[];
  }>(externalProductListData.selectedFilters);
  const breadcrumbsData = [
    { name: t("breadcrumbs.home"), url: "/" },
    { name: t("breadcrumbs.campaignhub"), url: campaignHubUrl },
    { name: "Mega sale", url: "" },
  ];
  const { storesData } = externalProductListData;
  const { createCommonRequestData, numberOfColumnsPerDeviceOnPlp } =
    useCommonProductListRequestData({
      initialData: {
        numberOfColumnsPerDeviceOnPlp:
          externalProductListData.numberOfColumnsPerDeviceOnPlp,
        storeIds: storesData.map(({ id }) => id),
      },
    });
  const [preferredStores, setPreferredStores] =
    useState<PreferredStores | null>(null);
  const { setCategoryPath: setCategoryPathOnUrl } = useProductListParams(true);
  const [categoryId, setCategoryId] = useState<string>();
  const {
    cookies,
    headers,
    isTeamsales,
    options,
    query,
    siteUid,
    toggle_elevate_cluster_landing_page,
    toggle_force_member_price_display,
    userKeys,
  } = createCommonRequestData({
    preferredStores,
    selectedFilters,
    storesData,
  });
  const { data, isFetching } = useQuery({
    queryKey: [
      QUERY_KEYS.CAMPAIGN_PRODUC_LIST,
      {
        categoryId,
        campaignId,
        isLoggedIn,
        cookies,
        headers,
        isTeamsales,
        options,
        query,
        siteUid,
        toggle_elevate_cluster_landing_page,
        toggle_force_member_price_display,
        userKeys,
        additionalCampaignIds,
        numberOfProductsPerPage,
      },
    ],
    queryFn: () =>
      fetchProductList.campaign({
        cookies,
        headers,
        additionalCampaignIds,
        campaignId,
        isTeamsales,
        options,
        query,
        siteUid,
        stores: externalProductListData.storesData,
        toggle_elevate_cluster_landing_page,
        toggle_force_member_price_display,
        userKeys,
        numberOfProductsPerPage,
        ...(categoryId !== undefined && {
          categoryIds: [categoryId],
        }),
      }),
  });

  useEffect(() => {
    const { productListingSmallBannersIds: ids } = abTestsPage ?? {};
    if (!isTestVariantActive || hasNoValue(ids)) {
      return;
    }
    void (async () => {
      try {
        setProductListingSmallBanners(
          await fetchProductListingSmallBannersData(ids)
        );
      } catch (error) {
        log.error(
          "Failed fetching product listing small banners for test variant"
        );
      }
    })();
  }, [abTestsPage, isTestVariantActive]);

  useEffect(() => {
    // Campaign pages may still have `?showAll=true` which now is replaced with `categoryPath=showAll`
    const params = new URLSearchParams(window.location.search);
    if (params.has(FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER)) {
      setCategoryPathOnUrl([FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER]);
    }
  }, [setCategoryPathOnUrl]);

  const {
    backgroundColor,
    countdownTimerSettings,
    desktopImage,
    disclaimerInformation,
    heroDescription,
    heroLabel,
    heroTitle = "",
    imageOverlay = false,
    mobileImage,
    textAlignment = DigitalCampaignCategoryHeroBannerTextAlignmentEnum.center,
    textColor = "black",
  } = selectedHeroBanner;

  const onCategoryChange = useCallback(
    (path: string[], isAdditionalView = false) => {
      const shouldShowAll = !isAdditionalView && isEmpty(path);
      setIsAdditionalViewSelected(isAdditionalView);
      const newPath = shouldShowAll
        ? path
        : path.filter(withoutShowAllParameter);
      setCategoryPath((state) => (isEqual(state, newPath) ? state : newPath)); // Return same reference if is equal
      setCategoryPathOnUrl(
        shouldShowAll ? [FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER] : newPath
      );

      const selectedBanner = pickCampaignHeroBanner(
        path[0] ?? "",
        landingPageBannerContent,
        categoryBanners
      );
      setSelectedHeroBanner(selectedBanner);
      const categoryIdFromPath = path.at(-1);
      setCategoryId(categoryIdFromPath);
    },
    [categoryBanners, landingPageBannerContent, setCategoryPathOnUrl]
  );

  return (
    <>
      <Head>
        <link rel="canonical" href={canonical} />
        <meta name="robots" content={"noindex"} />
        <title>{pageTitle}</title>
      </Head>
      <div className="container">
        <BreadcrumbsContainer>
          <Breadcrumbs
            breadcrumbs={breadcrumbsData}
            homeUrl={frontEndServerUrl}
            shouldUseNextLink={false}
          />
        </BreadcrumbsContainer>
      </div>
      <SiteContainer hideBottomPadding={true} hasPadding={true}>
        <h1>{t("campaign.hub.campaigns")}</h1>
      </SiteContainer>
      <CampaignHeroBanner
        title={heroTitle}
        textColor={textColor}
        textAlignment={textAlignment}
        imageOverlay={imageOverlay}
        label={heroLabel}
        description={heroDescription}
        mobileImage={mobileImage}
        desktopImage={desktopImage}
        countdownTimerSettings={countdownTimerSettings}
        backgroundColor={backgroundColor?.value}
        ipaperLink={ipaperLink}
        disclaimerInformation={disclaimerInformation}
      />
      <SectionWrapper className="component-standard-spacing">
        <>
          <section className="container">
            {ipaperLink !== undefined && isMobile && (
              <NewsletterLink url={ipaperLink} />
            )}
            <CampaignCategorySelector
              categories={categoriesOfVisibleProducts}
              categoryPath={categoryPath}
              additionalViewSelectorLabel={landingPageSelectorLabel}
              onCategoryChange={onCategoryChange}
            />
          </section>
          {isAdditionalViewSelected ? (
            gridSections.map(
              (
                {
                  gridContainers,
                  sectionName,
                  visibleContainersCount,
                  buttonUrl,
                  buttonText,
                },
                index
              ) =>
                gridContainers !== undefined && (
                  <GridSection
                    key={index}
                    name={sectionName}
                    gridContainers={gridContainers}
                    visibleContainersCount={visibleContainersCount ?? undefined}
                    priceData={bannerPriceData}
                    buttonText={buttonText ?? undefined}
                    buttonUrl={buttonUrl ?? null}
                  />
                )
            )
          ) : (
            <SiteContainer>
              <ProductList
                autoFetch={false}
                availability={data?.availability ?? []}
                categoryId={categoryId ?? null}
                facets={data?.facets ?? []}
                isFetchingProducts={isFetching}
                longTailFacets={null}
                longTailPattern={null}
                onFilterChange={({
                  availability,
                  selectedFilters,
                  selectedStores,
                }) => {
                  setPreferredStores({
                    availability,
                    ids: selectedStores,
                  });
                  setSelectedFilters(selectedFilters);
                }}
                selectedColumnsNumber={numberOfColumnsPerDeviceOnPlp}
                selectedFilters={data?.selectedFilters ?? {}}
                sortOrderData={data?.sortOrderData}
                storesData={storesData}
                totalHits={data?.totalHits ?? 0}
                isArticleNumbersSearch={false}
              >
                <ProductListItems
                  productListingSmallBanner={productListingSmallBanners}
                  products={data?.products ?? []}
                  selectedColumnsNumber={numberOfColumnsPerDeviceOnPlp}
                />
              </ProductList>
            </SiteContainer>
          )}
          <section className="container">
            <CampaignCategorySelector
              categories={categoriesOfVisibleProducts}
              categoryPath={categoryPath}
              additionalViewSelectorLabel={landingPageSelectorLabel}
              onCategoryChange={onCategoryChange}
            />
          </section>
        </>
      </SectionWrapper>
    </>
  );
};

export default DigitalCampaignPage;

export const getServerSideProps = withPageData(getCampaignPageProps);
